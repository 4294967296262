<template>
  <AppLink
    :to="getShopRoute('/wishlist')"
    icon-classes="text-white m-0 size-5"
    icon="heart"
    class="relative px-2 xl:px-4"
  >
    <ClientOnly>
      <span
        v-if="count > 0"
        class="absolute left-5 top-3 flex items-center justify-center rounded-full bg-white text-2xs font-bold text-black lg:left-7"
        :class="{
          'size-3': count < 10,
          'h-3 w-auto px-1': count > 9,
        }"
      >
        <template v-if="count < 100">{{ count }}</template>
        <template v-else>99+</template>
      </span>
    </ClientOnly>
  </AppLink>
</template>

<script setup lang="ts">
const { count } = useWishlist()
const { getShopRoute } = useShopRouter()
</script>
