<template>
  <div class="relative flex flex-col items-start">
    <ul
      class="flex space-y-2.5 lg:flex-col"
      :aria-label="$t('switch_language')"
    >
      <li class="hidden lg:inline-block">
        <span class="font-bold">{{ $t('language') }}</span>
      </li>
      <li
        v-for="option in options"
        :key="option.code"
        class="mr-3 uppercase lg:mr-0"
        :class="{ 'font-bold': option.code === currentLocale }"
      >
        <button @click="switchLanguage(option)">
          <span class="hidden lg:inline">{{ $t(option.code) }}</span>
          <span class="uppercase lg:hidden">{{ option.code }}</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type { LocaleObject } from '@nuxtjs/i18n'

type LanguageSwitchOption = LocaleObject & {
  languageId?: string
  alternate?: {
    locale: string
    url: string
  } | null
}

const switchLocalePath = useSwitchLocalePath()
const { locale: currentLocale } = useI18n()
const { locales } = useI18n()
const { alternates, shopPath } = useAlternates()
const { changeLanguage } = useInternationalization()
const { apiClient } = useShopwareContext()

const options = computed(() => {
  const items = locales.value as (LocaleObject & { languageId?: string })[]
  return items.map((locale) => ({
    ...locale,
    alternate: alternates.value?.find((a) => a.locale === locale.code),
  }))
})

const switchLanguage = async (option: LanguageSwitchOption) => {
  // store before we update anything
  const shopTo = `/${option.code}/shop${shopPath.value}`

  apiClient.defaultHeaders.apply({ 'sw-language-id': option.languageId })
  const shopUpdate = changeLanguage(option.languageId!)

  // statamic page
  if (option.alternate) {
    return navigateTo(option.alternate.url)
  }

  // shop page
  if (shopPath.value) {
    await shopUpdate
    return navigateTo(shopTo)
  }

  // fallback
  const localePath = switchLocalePath(option.code)

  // user swicthed to fast for page to fetch
  if (localePath.startsWith('/shop/')) {
    return
  }

  return navigateTo(switchLocalePath(option.code))
}
</script>
