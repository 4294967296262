<template>
  <div
    class="fixed inset-0 z-50 flex flex-col overflow-auto bg-black py-16 text-white xl:hidden"
  >
    <ul class="divide-y divide-gray-400 border-y border-gray-400 font-bold">
      <!-- Shopware Nav Items -->
      <Container
        v-for="navItemLevel1 in shopNavigation"
        :key="navItemLevel1.id"
        tag="li"
      >
        <MobileMenuNavItem
          :items="navItemLevel1.children"
          :depth="isProfessional ? 3 : 2"
        >
          {{ navItemLevel1.name }}
        </MobileMenuNavItem>
      </Container>

      <!-- CMS Nav Items -->
      <Container v-for="(navItem, index) in navItems" :key="index" tag="li">
        <MobileMenuNavItem v-if="navItem?.children" :items="navItem.children">
          {{ navItem.page.title }}
        </MobileMenuNavItem>
      </Container>

      <!-- System Nav Items -->
      <Container tag="li">
        <MobileMenuNavItem icon="user">
          <NuxtLink :to="localePath('/shop/account/profile')">
            {{ $t('account.myAccount') }}
          </NuxtLink>
        </MobileMenuNavItem>
      </Container>
      <Container tag="li">
        <MobileMenuNavItem icon="heart">
          <NuxtLink :to="localePath('/shop/wishlist')">
            {{ $t('account.wishlist.title') }}
          </NuxtLink>
        </MobileMenuNavItem>
      </Container>
      <Container tag="li">
        <MobileMenuNavItem icon="cart">
          <NuxtLink :to="localePath('/shop/checkout/cart')">
            {{ $t('cart.cart') }}
          </NuxtLink>
        </MobileMenuNavItem>
      </Container>
      <Container tag="li">
        <MobileMenuNavItem icon="search">
          <NuxtLink :to="localePath('/search')">
            {{ $t('general.search') }}
          </NuxtLink>
        </MobileMenuNavItem>
      </Container>
    </ul>
    <div class="flex flex-1 items-end justify-between p-4 text-sm">
      <ClientOnly>
        <LangSwitcher />
      </ClientOnly>

      <div class="flex space-x-3 font-bold">
        <a
          v-if="general?.support_phone"
          :href="`tel:${general?.support_phone}`"
          class="inline-block"
        >
          {{ general?.support_phone }}
        </a>
        <a
          v-if="general?.support_email"
          :href="`mailto:${general?.support_email}`"
          class="inline-block"
        >
          {{ general?.support_email }}
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const cms = inject(CMS_GLOBALS)
const shopNavigation = inject(SHOP_GLOBALS)!

const localePath = useLocalePath()
const { user } = useUser()
const { isProfessional } = useCustomer(user)

const general = computed(() => cms?.value.general)

const navItems = computed(() => {
  const links = cms?.value.main?.tree
  if (!links) return []
  return links.flatMap((link) => {
    if (link?.page?.is_menu) {
      return link?.children
    }
    return link
  })
})
</script>
